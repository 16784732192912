import 'url-search-params-polyfill';
import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import { navigateTo } from 'gatsby-link';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/products.styles';
import { TinyPagination } from 'react-pagination-custom';
import ReactHtmlParser from 'react-html-parser';

import PagesHero from '../components/PagesHero';
import placeHolderImage from '../images/placeholder.png';
import FilterWidget from '../components/FilterWidget';
import { breakpoints, colors } from '../style-utilities/variables';
import FilterButton from '../components/FilterButton';

import FilterIcon from '../images/filter-icon.png';

const DisplayProduct = styled.div`
  grid-area: DisplayProduct;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));

  /* // Pretty gross IE workaround. Sorry everybody. */
  @media (-ms-high-contrast: none) {
    .SingleProduct {
      width: 45%;
      display: inline-block !important;
    }
  }
  /*@media (-ms-high-contrast: none) and (min-width: ${breakpoints.tablet}) {
    .SingleProduct {
      width: 45%;
    }
  }*/
  @media (-ms-high-contrast: none) and (min-width: ${breakpoints.desktop}) {
    .SingleProduct {
      width: 30%;
    }
  }

  grid-template-areas:
    'productHeader productHeader'
    'auto auto'
    'auto auto'
    'auto auto'
    'auto auto'
    'auto auto'
    'auto auto'
    'page page';
  grid-auto-columns: 1fr 1fr;

  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-areas:
      'productHeader productHeader productHeader'
      'auto auto auto'
      'auto auto auto'
      'auto auto auto'
      'auto auto auto'
      'page page page';
    grid-auto-columns: 1fr 1fr 1fr;
  }

  .result-number {
    grid-area: productHeader;
    padding: 0 20px;
    font-size: 18px;
    margin: 10px 0 0;
    font-weight: bold;
  }

  /* Pagination CSS */
  .tiny-pagination-container {
    display: flex;
    grid-area: page;
    .counter-container {
      display: none;
    }
    justify-content: center;
    @media (min-width: ${breakpoints.tablet}) {
      justify-content: flex-end;
    }
  }
  .btns-number-container {
    display: inherit;
  }
  .counter-container span,
  .btns-number-container button {
    font-size: 16px;
    font-weight: 500;
  }
  .page {
    border: none;
    background: transparent;
  }
  .selected-page {
    color: ${colors.primary};
  }
`;

const Div = styled.div`
  .hero-section {
    background: ${props => `url(${props.bgImage}) no-repeat center`};
    background-size: cover;
    position: relative;
  }

  .InnerBox {
    display: -ms-inline-flexbox;
    .title {
      margin: 0 auto;
      z-index: 0;
      margin-bottom: 20px;
      h1 {
        margin: 0;
        padding: 15px 20px;
        color: #ffffff;
        font-size: 40px;
        z-index: 4;
      }
    }
  }
  button {
    display: flex;
    font-size: 20px;
    margin: auto;
    cursor: pointer;
  }

  .SingleProduct {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;
    text-decoration: none;
    img {
      @media (max-width: ${breakpoints.mobileMax}) {
        max-width: 160px;
        max-height: 160px;
      }
      max-width: 240px;
    }
    p {
      color: ${colors.secondary};
      font-family: 'GothamMedium';
    }
  }
  .FilterButton {
    display: none;
  }
  .main-section {
    .product-card {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .product-img {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        flex-shrink: 0; // IE workaround
        max-height: 200px;
      }
      h2 {
        font-weight: bold;
        min-height: 70px;
        font-size: 16px;
      }
    }
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: 3fr 9fr;
    grid-template-columns: 30% 70%;
    grid-template-areas: 'Widget DisplayProduct';
    padding: 40px 20px;

    .WidgetComponent {
      grid-area: Widget;
      -ms-grid-row: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      grid-column: 1;
    }

    ${DisplayProduct} {
      -ms-grid-row: 1;
      grid-row: 1;
      -ms-grid-column: 2;
      grid-column: 2;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .main-section {
      display: block;
      grid-template-columns: 350px auto;
      .product-card {
        h2 {
          min-height: 80px;
        }
      }
    }

    ${DisplayProduct} {
      #result-number {
        text-align: center;
        h3 {
          font-size: 18px;
        }
      }
      .counter-container,
      .page {
        font-size: 14px;
      }
    }

    .SingleProduct {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .FilterButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      width: 140px;
      bottom: 20px;
      padding: 10px 30px;
      margin-bottom: 10px;
      color: #ffffff;
      &.ie-filter-button {
        position: fixed;
        transform: translateX(-50%);
        left: 50%;
      }
      div {
        font-weight: 600;
        font-size: 18px;
        padding-top: 6px;
        letter-spacing: 1px;
      }
    }
  }

  /*@media (-ms-high-contrast: none) {
    .FilterButton {
      position: fixed;
      transform: translateX(-50%);
      left: 50%;
    }
  }
  @media (-ms-high-contrast: none) and (min-width: ${breakpoints.tablet}) {
    .FilterButton {
      position: sticky;
    }
  }*/

  @media (max-width: ${breakpoints.mobileMax}) {
    .main-section {
      .product-card {
        h2 {
          min-height: 90px;
        }
      }
    }
  }
  @media(min-width: 1400px ) {
    .product-img {
      max-width: 320px;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class AllProducts extends Component {
  state = {
    filterElements: [],
    selectedPageId: 1,
    filterOpen: false,
    currentQueryString: null
  };
  /**
   * CMS: Products from Wordpress
   */
  allTaxonomies = this.props.data.allWordpressWpTaxonomies.edges;
  products = this.props.data.allWordpressWpProducts.edges;
  productLine = this.props.data.allWordpressWpProductLine.edges;
  //Pasta Type Type
  attribute1 = this.props.data.allWordpressWpAttribute1.edges;

  componentDidMount() {
    this.updateFilters();

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // This simulates the sticky position for the filter button.
    if (isIE11) {
      var filterButton = document.getElementsByClassName('FilterButton')[0];
      var footer = document.getElementsByClassName('footer-wrap')[0];

      filterButton.classList.add('ie-filter-button');

      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      var footerHeight = Math.max(
        footer.scrollHeight,
        footer.offsetHeight,
        footer.clientHeight
      );

      var nearBottom =
        height - footerHeight - document.documentElement.clientHeight;

      document.addEventListener('scroll', function() {
        var top =
          (document.documentElement && document.documentElement.scrollTop) ||
          document.body.scrollTop;
        if (
          top > nearBottom &&
          filterButton.classList.contains('ie-filter-button')
        ) {
          filterButton.classList.remove('ie-filter-button');
        }
        if (
          top < nearBottom &&
          !filterButton.classList.contains('ie-filter-button')
        ) {
          filterButton.classList.add('ie-filter-button');
        }
      });
    }
  }


    componentDidUpdate() {
      if (
        this.state.currentQueryString &&
        this.props.location.search !== this.state.currentQueryString
      ) {
        this.updateFilters();
      } else {
        this.updateQueryString();
      }
    }

    updateFilters = () => {
      let search = this.props.location.search;
      let params = new URLSearchParams(search);
      //Check for matched current query string in URL
      if (search) {
        this.setState({ currentQueryString: search });
      }
      //Get selected option from mega menu
      let pickedOption = params.get('option');
      let optionArray = [];
      if (pickedOption !== null) {
        optionArray = pickedOption.split(',');
        this.setState({ filterElements: optionArray });
      }

      /* Checks to see if a page is in the URL */
      let pagePosition = params.get('page');
      if (pagePosition !== null) {
        this.setState({ selectedPageId: parseInt(pagePosition) });
      }
    };

    updateQueryString = () => {
      let search = this.props.location.search;
      let params = new URLSearchParams(search);
      let updated = false;
      /**
       * Get filtered by "Cook By" in Recipes Mega Menu
       */
      let items = params.get('option');
      let newItems = this.state.filterElements ? this.state.filterElements.join() : '';
      if ((items && items != newItems) || (items && !newItems.length) || (!items && newItems.length)) {
        !newItems.length ? params.delete('option') : params.set('option',newItems);
        updated = true;
      }

      if (updated) {
        history.pushState(null, '', this.props.location.pathname + (params.toString() ? ('?' + params.toString()) : ''));
        this.props.location.search = params.toString();
        this.setState({ currentQueryString: params.toString() });
      }
    };

  /**
   * METHOD: Pass State - filterElements to the FilterWidget Component
   * @param - filters are the filtering options
   */
  setFilters = filters => {
    this.setState({
      filterElements: filters
    });
  };
  /**
   * METHOD: Render products by filtering options
   */
  renderFilteredProducts = () => {
    return this.products.map(
      ({
        node: { title, wordpress_id, product_line, attribute_1, acf, slug }
      }) => {
        // Get slugs for product's product_category
        const productLineSlugs = this.productLine.filter(el =>
          product_line.some(e => e === el.node.wordpress_id)
        );
        // Get slugs for product's pasta_shape
        const attributeOneSlugs = this.attribute1.filter(el =>
          attribute_1.some(e => e === el.node.wordpress_id)
        );

        let display = true;

        if (
          (!productLineSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
            this.productLine.some(el =>
              this.state.filterElements.includes(el.node.slug)
            )) ||
          (!attributeOneSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
            this.attribute1.some(el =>
              this.state.filterElements.includes(el.node.slug)
            ))
        ) {
          display = false;
        }

        if (display) {
          return (
            <Link
              to={`/products/${slug}`}
              className="SingleProduct"
              key={wordpress_id}
            >
              <div className="product-card">
                <div className="product-img">
                  {acf.product_image !== null ? (
                    <img
                      src={acf.product_image.source_url}
                      alt="placeHolderImage"
                    />
                  ) : (
                    <img src={placeHolderImage} alt="placeHolderImage" />
                  )}
                </div>
                {/* TODO: Ask creative to provide a place holder image for ones that have no product image  */}
                <h2>{ReactHtmlParser(title)}</h2>
              </div>
            </Link>
          );
        }
      }
    );
  };

  /**
   * METHOD: Scroll to top on pagination click
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(
      prevState => {
        return {
          ...prevState,
          selectedPageId: id
        };
      },
      () => {
        const { pathname } = this.props.location;
        const url = `${pathname}?page=${this.state.selectedPageId}`;
        navigateTo(url);
      }
    );
  }
  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case 'Prev':
        this.changePage(selectedPageId - 1);
        break;
      case 'Next':
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }
  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;

    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? 'selected-page' : ''}`}
      >
        {id}
      </button>
    );
  };
  /**
   * METHOD: Toggle Filter Widget for Mobile Version
   */
  filterOpenHandler = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  render() {
    const filterObj = {
      'Pasta Line': this.productLine.map(item => item),
      'Pasta Type': this.attribute1.map(item => item)
    };

    // Arrays of results being displayed on the UI
    let conditionalResults = this.renderFilteredProducts().filter(
      item => typeof item !== 'undefined'
    );
    const itemPerPage = 12;

    if (
      conditionalResults.length <
      (this.state.selectedPageId - 1) * itemPerPage
    ) {
      this.changePage(1);
    }

    let conditionalProductsPerPage = [...conditionalResults];
    conditionalProductsPerPage = conditionalProductsPerPage.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );

    return (
      <Div
        bgImage={
          this.props.data.wordpressPage.acf.hero_image === null
            ? placeHolderImage
            : this.props.data.wordpressPage.acf.hero_image.source_url
        }
      >
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <PagesHero className="hero-section">
          <div className="InnerBox">
            <div className="title">
              <h1>{ReactHtmlParser(this.props.data.wordpressPage.acf.hero_headline)}</h1>
            </div>
          </div>
        </PagesHero>
        <div className="main-section">
          <div className="WidgetComponent">
            <FilterWidget
              title="Filter Products"
              state={this.state.filterElements}
              setFilters={this.setFilters}
              filterList={filterObj}
              tagTitle=""
              filterOpen={this.state.filterOpen}
              filterToggle={this.filterOpenHandler}
              ctaText="View Products"
            />
          </div>
          {/* rendered products */}
          <DisplayProduct>
            <>
              <p className="result-number">
                {conditionalResults.length} Products
              </p>

              {conditionalProductsPerPage}
              {conditionalResults.length > itemPerPage ?
              <TinyPagination
                total={conditionalResults.length}
                selectedPageId={this.state.selectedPageId}
                itemPerPage={itemPerPage}
                renderBtnNumber={this.renderBtnNumber}
                maxBtnNumbers={10}
                preKey="Prev"
                nextKey="Next"
              />
              : ''}
            </>
          </DisplayProduct>
        </div>
        <FilterButton
          className="FilterButton"
          filterToggle={this.filterOpenHandler}
        />
      </Div>
    );
  }
}

export default AllProducts;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "our-products" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        hero_headline
        hero_image {
          source_url
        }
      }
    }
    allWordpressWpProducts {
      edges {
        node {
          title
          slug
          wordpress_id
          product_category
          product_line
          attribute_1
          attribute_3
          attribute_4
          acf {
            product_sizes
            product_serving_size
            product_cooking_time
            short_description
            product_image {
              source_url
            }
          }
        }
      }
    }
    allWordpressWpProductLine {
      edges {
        node {
          slug
          wordpress_id
          name
        }
      }
    }
    allWordpressWpAttribute1 {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    wordpressWpOptionsPage(slug: { eq: "site-options" }) {
      acf {
        product_attributes
      }
    }
    allWordpressWpTaxonomies {
      edges {
        node {
          name
          slug
          types
        }
      }
    }
  }
`;
